import React from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import {
    setRadioValue
} from "../../store/actions/aboutYou";

const AboutYouSumGender = ( props ) => {

    const additionalClass = props.isSpouse ? 3 : 6
    return (
        <Row className={classes.Row}>
            <Col xs={12} sm={6}>
                <strong>Gender</strong>
            </Col>
            <Col xs={12} sm={additionalClass}>
               <select
                   name='iAmGender'
                   value={props.iAm}
                   onChange={props.setRadio}
               >
                   <option value = "Male">Male</option>
                   <option value = "Female">Female</option>
               </select>
            </Col>

            {
                props.isSpouse &&
                <Col xs={12} sm={3}>
                           <select
                               name='sexSpouse'
                               value={props.sexSpouse}
                               onChange={props.setRadio}
                           >
                               <option value = "Male">Male</option>
                               <option value = "Female">Female</option>
                           </select>
                        </Col>
            }

            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        iAm: state.aboutYou.iAm,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        sexSpouse: state.aboutYou.myFamily.spouseData.gender,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setRadio : event => dispatch(setRadioValue(event)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouSumGender);