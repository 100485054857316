import React, { useState } from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import Error from "../Error/Error";
import {
    setAboutYouSumCompleted,
    setAboutYouSumRetireAgeError,
    setRetireAge, setSpouseRetireAge,
    setYearsIncome
} from "../../store/actions/aboutYou";
import { connect } from "react-redux";
import { yearsValidation } from "../../validation/validation";

const AboutYouSumRetirementAge = ( props ) => {
    const [retireAge, setRetireAge] = useState(props.retireAge || 65)
    const spouseRetireAge= parseInt(props.spouseRetireAge) || 65
    const [errorRetireAge, setErrorRetireAge] = useState({
                                                             error: false,
                                                             errorMessage: `Must be between ${+props.iAmYearsOld+1} to 80`
                                                         })

    const onChangeRetireAge = (e) => {
        const val = e.target.value !== '' ? parseInt(e.target.value, 10) : 0
        const spouseRetireAgeVal = parseInt(props.spouseYearsOld) - parseInt(props.iAmYearsOld) + val
        setErrorRetireAge({
                              ...errorRetireAge,
                              error: yearsValidation(val, +props.iAmYearsOld+1, 81),
                              errorMessage: `Must be between ${+props.iAmYearsOld+1} to 80`
                          })

        setRetireAge(val)
        props.setRetireAge(val)

        const yearsIncomeVal = parseInt(props.averageLife) - val
        props.setYearsIncome(yearsIncomeVal, parseInt(props.averageLife))

        props.setAboutYouSumRetireAgeError(props.iAmYearsOld > val)

        props.setAboutYouSumCompleted(yearsValidation(val, +props.iAmYearsOld+1, 81))

        setSpouseRetireAgeHandler(spouseRetireAgeVal)
    }


    function setSpouseRetireAgeHandler(spouseRetireAgeVal) {
        props.setSpouseRetireAge(spouseRetireAgeVal)
    }

    const additionalClass = props.isSpouse ? 3 : 6

    return (
        <Row className={classes.Row}>
            <Col xs={12} sm={6}>
                <strong>Retirement age</strong>
            </Col>
            <Col xs={12} sm={additionalClass}>
                {
                    errorRetireAge.error &&
                    <Error message={errorRetireAge.errorMessage} style={{top: -56}}/>
                }
                <input
                    type = "text"
                    name='YouRetirementAge'
                    value={retireAge}
                    onChange={onChangeRetireAge}
                />
            </Col>
            {
                props.isSpouse &&
                <Col xs={12} sm={3}>
                   <input
                       type = "text"
                       name='SpouseRetirementAge'
                       value={spouseRetireAge}
                       disabled='disabled'/>
                </Col>
            }

            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        iAm: state.aboutYou.iAm,
        iAmYearsOld: state.aboutYou.iAmYearsOld,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        spouseYearsOld: state.aboutYou.myFamily.spouseData.yearsOld,
        retireAge: state.aboutYou.retireAge,
        spouseRetireAge: state.aboutYou.spouseRetireAge,
        averageLife: state.aboutYou.averageLife,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setRetireAge: val => dispatch(setRetireAge(val)),
        setSpouseRetireAge: val => dispatch(setSpouseRetireAge(val)),
        setYearsIncome: (yearsVal, averageLife) => dispatch(setYearsIncome(yearsVal, averageLife)),
        setAboutYouSumRetireAgeError: val => dispatch(setAboutYouSumRetireAgeError(val)),
        setAboutYouSumCompleted: val => dispatch(setAboutYouSumCompleted(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouSumRetirementAge);