import React from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";

const AboutYouSumYearsInRetirement = ( props ) => {
    const yearsIncome = props.yearsIncome || 0

    return (
        <Row className={classes.Row}>
            <Col xs={12} sm={6}>
                <strong>Years in retirement</strong>
            </Col>
            <Col xs={12} sm={6}>
               <input
                   type = "text"
                   name='YearsInRetirement'
                   value={yearsIncome}
                   disabled='disabled'
               />
            </Col>
            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        yearsIncome: state.aboutYou.yearsIncome,
    }
}

export default connect(mapStateToProps, null)(AboutYouSumYearsInRetirement);