import React, { useState } from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import Error from "../Error/Error";
import { connect } from "react-redux";
import {
    setIAmYearsOldHandler,
    setSpouseRetireAge,
    setSpouseYearsOldHandler,
    setAboutYouSumRetireAgeError,
    setAboutYouSumCompleted
} from "../../store/actions/aboutYou";
import { yearsValidation } from "../../validation/validation";

const AboutYouSumAge = ( props ) => {

    const [youYearsOld, setYouYearsOld] = useState(props.iAmYearsOld || 18)
    const [errorMyAge, setErrorMyAge] = useState({
                                                     error: false,
                                                     errorMessage: 'Must be between 18 to 71'
                                                 })

    const [youSpouseYearsOld, setSpouseYearsOld] = useState(props.spouseYearsOld || 18)
    const [errorSpouseAge, setErrorSpouseAge] = useState({
                                                             error: false,
                                                             errorMessage: 'Must be between 18 to 71'
                                                         })
    const [errorRetirementAge, setErrorRetirementAge] = useState({
                                                             error: false,
                                                             errorMessage: `Must be between ${props.iAmYearsOld} to 80`
                                                         })

    const additionalClass = props.isSpouse ? 3 : 6


    function setSpouseRetireAgeHandler(spouseRetireAgeVal) {
        props.setSpouseRetireAge(spouseRetireAgeVal)
    }

    const onChangeYearsOld = (e) => {
        const yearsVal = e.target.value !== '' ? parseInt(e.target.value, 10) : 0
        if(e.target.name === 'YouYearsOld'){
            setErrorMyAge({
                              ...errorMyAge,
                              error: yearsValidation(yearsVal, 18, 71)
                          })


            setYouYearsOld(yearsVal)
            props.setIAmYearsOld(yearsVal)

            setErrorRetirementAge({
                                 ...errorRetirementAge,
                                 error: yearsVal > props.retireAge,
                                 errorMessage: `Must be between ${+yearsVal+1} to 80`
                             })

            props.setAboutYouSumRetireAgeError(yearsVal > props.retireAge)
            props.setAboutYouSumCompleted((yearsVal > props.retireAge) || yearsValidation(yearsVal, 18, 71))

            setSpouseRetireAgeHandler(parseInt(props.spouseYearsOld) - parseInt(yearsVal) + parseInt(props.retireAge))

        }else {

            setErrorSpouseAge({
                                  ...errorSpouseAge,
                                  error: yearsValidation(yearsVal, 18, 71)
                              })


            setSpouseYearsOld(yearsVal)
            props.setSpouseYearsOld(yearsVal)

            props.setAboutYouSumCompleted(yearsValidation(yearsVal, 18, 71))

            setSpouseRetireAgeHandler(yearsVal - parseInt(props.iAmYearsOld) + parseInt(props.retireAge))
        }

    }

    return (
        <Row className={classes.Row}>
            <Col xs={12} sm={6}>
                <strong>Age</strong>
            </Col>
            <Col xs={12} sm={additionalClass}>
                {
                    errorMyAge.error &&
                    <Error message={errorMyAge.errorMessage} style={{top: -56}}/>
                }
                {
                    props.errorAboutYouSumRetireAge &&
                    <Error message={errorRetirementAge.errorMessage} style={{top: 5}}/>
                }
                <input
                    type = "text"
                    name='YouYearsOld'
                    value={youYearsOld}
                    onChange={onChangeYearsOld}
                />
            </Col>
            {
                props.isSpouse &&
                <Col xs={12} sm={3}>
                            {
                                errorSpouseAge.error &&
                                <Error message={errorSpouseAge.errorMessage} style={{top: -56}}/>
                            }
                    <input type = "text"
                           name='SpouseYearsOld'
                           value={youSpouseYearsOld}
                           onChange={onChangeYearsOld}
                    />
                        </Col>
            }

            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        iAmYearsOld: state.aboutYou.iAmYearsOld,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        spouseYearsOld: state.aboutYou.myFamily.spouseData.yearsOld,
        retireAge: state.aboutYou.retireAge,
        errorAboutYouSumRetireAge: state.aboutYou.errorAboutYouSumRetireAge,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setIAmYearsOld: val => dispatch(setIAmYearsOldHandler(val)),
        setSpouseYearsOld: val => dispatch(setSpouseYearsOldHandler(val)),
        setSpouseRetireAge: val => dispatch(setSpouseRetireAge(val)),
        setAboutYouSumRetireAgeError: val => dispatch(setAboutYouSumRetireAgeError(val)),
        setAboutYouSumCompleted: val => dispatch(setAboutYouSumCompleted(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouSumAge);