import React from 'react';
import classes from './AboutYouSummaryComp.module.scss'
import { connect } from "react-redux";
import { Grid } from 'react-flexbox-grid';
import Navigation from "../../Navigation/Navigation";
import AboutYouSumGender from "../AboutYouSumGender";
import AboutYouSumHeader from "../AboutYouSumHeader";
import AboutYouSumAge from "../AboutYouSumAge";
import AboutYouSumRetirementAge from "../AboutYouSumRetirementAge";
import AboutYouSumYearsInRetirement from "../AboutYouSumYearsInRetirement";
import AboutYouSumChildren from "../AboutYouSumChildren";
import AboutYouSumDependants from "../AboutYouSumDependants";
import AboutYouSumRentOrOwn from "../AboutYouSumRentOrOwn";
import AboutYouSumIncome from "../AboutYouSumIncome";
import AboutYouSumInflation from "../AboutYouSumInflation";
import AboutYouSumSave from "../AboutYouSumSave";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const AboutYouSummaryComp = ( props ) => {

    return (
        <React.Fragment>
            <form className={classes.AboutYouSummary}>
                <Grid>

                    <AboutYouSumHeader/>

                    <AboutYouSumGender/>

                    <AboutYouSumAge/>

                    <AboutYouSumRetirementAge/>

                    <AboutYouSumYearsInRetirement/>

                    <AboutYouSumChildren/>

                    <AboutYouSumDependants/>

                    <AboutYouSumRentOrOwn/>

                    <AboutYouSumIncome/>

                    <AboutYouSumInflation/>

                    <AboutYouSumSave/>

                </Grid>
            </form>

            <Navigation
                isCompleted={props.isCompleted}
                next={retirementCalcLinks.investorProfile}
            />
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        isCompleted: state.aboutYou.firstStepCompleted,
    }
}

export default connect(mapStateToProps, null)(AboutYouSummaryComp);