import React from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import {
    changeRentOrOwner
} from "../../store/actions/aboutYou";
import { connect } from "react-redux";

const AboutYouSumRentOrOwn = ( props ) => {

    const changeRentOrOwnerHandler = (e) => {
        props.changeRentOrOwner(e.target.value)
    }

    return (
        <Row className={classes.Row}>
            <Col xs={12} sm={6}>
                <strong>Rent or Own</strong>
            </Col>
            <Col xs={12} sm={6}>
                <select
                    name='rentOrOwn'
                    value={props.rentOrOwn}
                    onChange={changeRentOrOwnerHandler}
                >
                   <option value = "Rent">Rent</option>
                   <option value = "Own">Own</option>
               </select>
            </Col>
            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        rentOrOwn: state.aboutYou.rentOrOwn,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        changeRentOrOwner: val => dispatch(changeRentOrOwner(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouSumRentOrOwn);