import React from 'react';
import { Col, Row } from "react-flexbox-grid";
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { connect } from "react-redux";

const AboutYouSumChildren = ( props ) => {
    return (
            props.isChildren > 0 &&
                <Row className={classes.Row}>
                            <Col xs={12} sm={6}>
                                <strong>Children</strong>
                            </Col>
                            <Col xs={12} sm={6}>
                               <input
                                   type = "text"
                                   name='children'
                                   value={props.children}
                                   disabled='disabled'
                               />
                            </Col>
                            <Col xs={12} className={classes.Separator}><hr/></Col>
                        </Row>
    )
};

function mapStateToProps(state) {
    return {
        children: state.aboutYou.myFamily.childrenData.children.count,
        isChildren: state.aboutYou.myFamily.childrenData.isChildren,
    }
}

export default connect(mapStateToProps, null)(AboutYouSumChildren);