import React, { useState } from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import Error from "../Error/Error";
import {yearsValidation} from "../../validation/validation";
import {
    setAboutYouSumCompleted,
    setInflation
} from "../../store/actions/aboutYou";
import { connect } from "react-redux";

const AboutYouSumInflation = ( props ) => {

    const [inflation, setInflation] = useState(props.inflation ? props.inflation : 1.74)
    const [errorInflation, setErrorInflation] = useState({
                                                             error: false,
                                                             errorMessage: `Must be between 0 to 4`
                                                         })

    const integerRGEX = /[0-9]$/i;

    const onChangeInflation = (e) => {
        const val = e.target.value.replace('%', '')
        const error = yearsValidation(val, 0, 4) || !integerRGEX.test(val)

        setErrorInflation({
                              ...errorInflation,
                              error: error
                          })

        setInflation(val)
        props.setInflation(val)

        props.setAboutYouSumCompleted(error)

    }

    return (
        <Row className={classes.Row}>
            <Col xs={12} sm={6}>
                <strong>Inflation assumption</strong>
            </Col>
            <Col xs={12} sm={6}>
                {
                    errorInflation.error &&
                    <Error message={errorInflation.errorMessage} style={{top: -56}}/>
                }
                <input
                    type = "text"
                    name='inflation'
                    value={inflation + '%'}
                    onChange={onChangeInflation}
                />
            </Col>
            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        inflation: state.aboutYou.inflation,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInflation: val => dispatch(setInflation(val)),
        setAboutYouSumCompleted: val => dispatch(setAboutYouSumCompleted(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouSumInflation);