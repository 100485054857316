import React, { useState } from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import Error from "../Error/Error";
import {
    setAboutYouSumCompleted,
    setAnticipatedIncome,
} from "../../store/actions/aboutYou";
import { connect } from "react-redux";
import {yearsValidation} from "../../validation/validation";

const AboutYouSumIncome = ( props ) => {

    const [incomeInRetirement, setIncomeInRetirement] = useState(props.anticipatedIncome ? props.anticipatedIncome : 2400)
    const [errorIncomeInRetirement, setErrorIncomeInRetirement] = useState({
                                                                               error: false,
                                                                               errorMessage: `Must be between 0 to 10000`
                                                                           })
    const integerRGEX = /[0-9]$/i;

    const changeIncomeInRetirement = (e) => {
        const val = e.target.value.replace('$', '')
        const error = yearsValidation(val, 0, 10000) || !integerRGEX.test(val)
        setErrorIncomeInRetirement({
                                       ...errorIncomeInRetirement,
                                       error: error
                                   })

        setIncomeInRetirement(val)
        props.setAnticipatedIncome(val)

        props.setAboutYouSumCompleted(error)

    }

    return (
        <Row className={classes.Row}>
            <Col xs={12} sm={6}>
                <strong>Income needed in retirement</strong>
            </Col>
            <Col xs={12} sm={6}>
                {
                    errorIncomeInRetirement.error &&
                    <Error message={errorIncomeInRetirement.errorMessage} style={{top: -56}}/>
                }
                <input
                    type = "text"
                    name='incomeInRetirement'
                    value={'$' + incomeInRetirement}
                    onChange={changeIncomeInRetirement}
                />
            </Col>
            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        anticipatedIncome: state.aboutYou.anticipatedIncome,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setAnticipatedIncome: val => dispatch(setAnticipatedIncome(val)),
        setAboutYouSumCompleted: val => dispatch(setAboutYouSumCompleted(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouSumIncome);