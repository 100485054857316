import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import AboutYouSummaryComp from "../../../components/AboutYouSummary/AboutYouSummaryComp/AboutYouSummaryComp";
import { connect } from "react-redux";
import { setAboutYouSumCompleted } from "../../../store/actions/aboutYou";

const AboutYourSammary = ( props ) => {
    const titles = {
        layoutTitle: 'ABOUT YOU SUMMARY',
    }

    const error = false

    useEffect(() => {
        props.firstStepCompleted(error)
    }, [props, error])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep1={(7/7)*100}
            active={'step1'}
        >
            <Helmet>
                <title>{titles.layoutTitle}</title>
            </Helmet>

            <AboutYouSummaryComp/>

        </Layout>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        firstStepCompleted: (val) => dispatch(setAboutYouSumCompleted(val))
    }
}

export default connect(null, mapDispatchToProps)(AboutYourSammary);