import React from 'react';
import { Col, Row } from "react-flexbox-grid";
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { connect } from "react-redux";

const AboutYouSumDependants = ( props ) => {
    return (
            props.isDependants > 0 &&
                <Row className={classes.Row}>
                    <Col xs={12} sm={6}>
                        <strong>Dependants</strong>
                    </Col>
                    <Col xs={12} sm={6}>
                       <input
                           type = "text"
                           name='dependants'
                           value={props.dependants}
                           disabled='disabled'
                       />
                    </Col>
                    <Col xs={12} className={classes.Separator}><hr/></Col>
                </Row>
    )
};

function mapStateToProps(state) {
    return {
        dependants: state.aboutYou.myFamily.dependantsData.dependants.count,
        isDependants: state.aboutYou.myFamily.dependantsData.isDependants,
    }
}

export default connect(mapStateToProps, null)(AboutYouSumDependants);